import React, { useState } from 'react';
import { Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import Loader from '../atoms/Loader';
import { COLOR_BACKGROUND_LIGHT, COLOR_BORDER, COLOR_DARK_GRAY, COLOR_WHITE } from '../styles/colors';
import { displayDateTime } from '../services/converter';
import Pagination from '../atoms/Pagination';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface OurladsFileUploadAuditLogsProps {
  ourladsFileUploadAuditLogs: any[];
  loadingAuditLogs: boolean;
  fileNameAndDate: any;
  setCurrentAuditsLog: (value: number) => void;
  currentAuditsLog: number;
  limit: number;
}

interface dataType {
  id: number;
  teamName: string;
  odk: string;
  count: number;
  type: string
}

const useStyles = makeStyles(theme => ({
  activityWrap: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  tableWrap: {
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 0,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1, 0, 1, 1),
    textTransform: 'capitalize',

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 20px',
    '& > span': {
      display: 'inline-block',
    },
  },
  paginationWrapper: {
    padding: '0 20px',
  },
  paginationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
  noAuditsLogs: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
  }, 
  noAuditsLogsText: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  }
}));

export default function ImportDepthChartTeamTable(props: OurladsFileUploadAuditLogsProps) {
  const {
    ourladsFileUploadAuditLogs,
    loadingAuditLogs,
    fileNameAndDate,
    setCurrentAuditsLog,
    currentAuditsLog,
    limit
  } = props;
  const classes = useStyles();
  const totalPages = ourladsFileUploadAuditLogs && Math.ceil(ourladsFileUploadAuditLogs.length / limit);
  const itemsFrom = (currentAuditsLog - 1) * limit;
  const itemsTo = ourladsFileUploadAuditLogs && ourladsFileUploadAuditLogs.length < limit ? ourladsFileUploadAuditLogs.length : (limit * currentAuditsLog);

  function renderTableRow(data: dataType, index: number) {
    const {
      id,
      teamName,
      odk,
      count,
      type
    } = data;
    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
      >
        <TableCell className={classes.tableCell} align='center'>{teamName}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{odk}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{count}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{type}</TableCell>
      </TableRow>
    )
  };

  function renderTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell} align='center'>Team</TableCell>
            <TableCell className={classes.tableHeadCell} align='center'>ODK</TableCell>
            <TableCell className={classes.tableHeadCell} align='center'>Count</TableCell>
            <TableCell className={classes.tableHeadCell} align='center'>Status</TableCell>
          </TableRow>
        </TableHead>

        {ourladsFileUploadAuditLogs && ourladsFileUploadAuditLogs.length ? 
        <TableBody>
          {ourladsFileUploadAuditLogs.slice(itemsFrom, itemsTo).map(renderTableRow) }
        </TableBody>
        : <div className={classes.noAuditsLogs}>
          <div className={classes.noAuditsLogsText}>No Data Found</div>
          </div>}
      </Table>
    );
  }

  return (
    <>
      <div className={classes.tableInfo}>
        <span>{fileNameAndDate && fileNameAndDate.fileName ? `File: ${fileNameAndDate.fileName}` : ''}</span>
        <span>{fileNameAndDate && fileNameAndDate.createdAt ? `Date: ${displayDateTime(fileNameAndDate.createdAt, true)}` : ''}</span>
      </div>
      <Paper>
        <Loader inProgress={loadingAuditLogs} />
        <div className={classes.tableWrap}>
          {renderTable()}
          <Divider variant='fullWidth' light />
          <div className={classes.paginationWrapper}>
            <div className={classes.paginationContainer}>
              <span className={classes.paginationText}>
                Showing {itemsFrom + 1} to {itemsTo} of {ourladsFileUploadAuditLogs.length}
              </span>

              {totalPages > 1 && (
                <Pagination
                  className={classes.pagination}
                  currentPage={currentAuditsLog}
                  totalPages={totalPages}
                  onChange={page => setCurrentAuditsLog(page)}
                />
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  )
}
