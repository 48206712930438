import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SpeedIcon from '../icons/SpeedIcon';
import RedCircle from '../icons/RedCircleIcon';
import EnduranceIcon from '../icons/EnduranceIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Tooltip from '../atoms/Tooltip';
import InGameRating from '../types/InGameRating';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
} from '../styles/colors';

interface PlayerInGameRatingProps {
  className?: string;
  inGameRating: InGameRating;
  label?: string;
}

const BETTER_THAN_TOOLTIP_TEXT = 'Better than D-I position';

const useStyles = makeStyles((theme: Theme) => ({
  PlayerInGameRating: {},

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: 350,
    paddingRight: 0,
    marginLeft: 'auto',
    cursor: 'help',
  },
  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },
}), { name: PlayerInGameRating.name });

export default function PlayerInGameRating (props: PlayerInGameRatingProps) {
  const {
    className,
    inGameRating,
    label,
  } = props;
  const classes = useStyles();

  if (!inGameRating) return null;

  function formatToTenth(value: number): string {
    // Format the number to one decimal place using toFixed(1)
    return value.toFixed(1);
  }

  const noEvents = !(inGameRating?.MPH || inGameRating?.MPHPercentage || inGameRating?.timeToMax);
  if (noEvents) return null;

  return (
    <NamedBox
      className={clsx(classes.PlayerInGameRating, className)}
      name={label || 'In-Game Metrics'}
    >
      {(inGameRating?.MPH && inGameRating?.MPHPercentage)
          ? <ListItem
              icon={EnduranceIcon}
              title='Player Max Speed (MPH):'
              extendWith={true}
            >
              <b>{formatToTenth(inGameRating?.MPH)}</b>
    
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
                <div className={classes.progressLineWrapper}>
                  <ProgressLine
                    className={classes.progressLine}
                    value={inGameRating?.MPHPercentage}
                  />
                </div>
              </Tooltip>
            </ListItem>
          : null
          }

          {(inGameRating?.timeToMax)
          ? <ListItem
              icon={SpeedIcon}
              title='Time to Max Speed (Sec):'
              extendWith={true}
            >
              <b>{formatToTenth(inGameRating?.timeToMax)}</b>
            </ListItem>
            : null
          }

          {(inGameRating?.distanceTraveled)
          ? <ListItem
              icon={EnduranceIcon}
              title='Distance Travelled:'
              extendWith={true}
            >
              <b>{formatToTenth(inGameRating?.distanceTraveled)}</b>
            </ListItem>
            : null
          }
    </NamedBox>
  );
}
