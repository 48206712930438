import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RemoveIcon from '../icons/RemoveIcon';
import Loader from '../atoms/Loader';
import Action from '../atoms/Action';
import Download from '../atoms/Download';
import Toast, { ToastType } from '../atoms/Toast';
import AddDepthChartPlayers from '../molecules/AddDepthChartPlayers';
import DepthChart from './DepthChart';
import { inchesToFeetAndInches } from '../services/converter';
import gql from '../services/gql';
import exportXLSReport, { XLSExportType } from '../services/export-xls-report';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import DepthChartPosition, { DepthChartType } from '../types/DepthChartPosition';
import DepthChartPlayer from '../types/DepthChartPlayer';
import Player from '../types/Player';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import PlayerPAIStats from '../types/PlayerPAIStats';
import User from '../types/User';
import PlayerCombineStats from '../types/PlayerCombineStats';
import PlayerPPIStats from '../types/PlayerPPIStats';

interface TeamDepthChartProps {
  className?: string;
  type: DepthChartType;
  loading: boolean;
  team?: Team;
  teamPlayers: Player[];
  depthChartId?: number;
  depthChartSlug?: string;
  positions?: DepthChartPosition[];
  user: User;
  printed?: boolean;
  statsBeingCalculated?: boolean;
  onUpdatePositions: (positions: DepthChartPosition[]) => void;
  setLoading: (value: boolean) => void;
}

export const CHART_TYPE = {
  [DepthChartType.DEFENSIVE]: 'defense',
  [DepthChartType.OFFENSIVE]: 'offense',
  [DepthChartType.SPECIAL]: 'special',
};

const useStyles = makeStyles((theme: Theme) => ({
  teamDepthChart: {
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  titleWrapper: {
    padding: theme.spacing(1, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    ...theme.typography.h2,
  },

  addPlayersButton: {
    width: '50%',
    maxWidth: '500px',
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    padding: theme.spacing(0, 2.5),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  actionIcon: {
    width: '32px',
    height: '32px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },

  downloadButtons: {
    display: 'flex',
    marginLeft: 'auto',
  },

  hidden: {
    display: 'none',
  },
  printTitle: {
    padding: theme.spacing(0.5, 1),
  },

  [MEDIA.MOBILE]: {
    titleWrapper: {
      display: 'block',
      padding: theme.spacing(2),
    },

    addPlayersButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },

    actions: {
      padding: theme.spacing(2),
    },
    actionText: {
      fontSize: theme.typography.pxToRem(14),
    },
    actionIcon: {
      width: '22px',
      height: '22px',
    },
  },

  '@media (max-width: 400px)': {
    actions: {
      display: 'block',
    },

    downloadButtons: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
}), { name: TeamDepthChart.name });

export default function TeamDepthChart (props:TeamDepthChartProps) {
  const {
    className,
    type,
    loading = true,
    team,
    teamPlayers,
    depthChartId,
    depthChartSlug,
    positions,
    user,
    printed = false,
    statsBeingCalculated = false,
    onUpdatePositions = () => {},
    setLoading,
  } = props;
  const classes = useStyles();

  const [teamDepthChartLoading, setTeamDepthChartLoading] = useState<boolean>(loading);
  const [selectedDepthChartPlayers, setSelectedDepthChartPlayers] = useState<DepthChartPlayer[]>([]);
  const [editedDepthChartPlayers, setEditedDepthChartPlayers] = useState<DepthChartPlayer[]>([]);
  const [selectedAddPlayers, setSelectedAddPlayers] = useState<Player[]>([]);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const positionsToRender = (positions || [])
    .sort(sortPositions)
    .map(position => ({
      ...position,
      players: position.players.map(player => ({
        ...player,
        height: player.height && typeof player.height === 'number'
          ? inchesToFeetAndInches(player.height as number)
          : (player.height || ''),
      })) as DepthChartPlayer[]
    })) as DepthChartPosition[];

  useEffect(() => {
    setTeamDepthChartLoading(loading);
  }, [loading]);

  function fetchPlayerDetails (playerId:number):Promise<Player | void> {
    return gql(`
      player (id: ${playerId}) {
        id
        slug
        firstName
        lastName
        pai
        ppi
        combine
        highSchools
        isSpeedRecruit
        isPowerRecruit
        playerColleges {
          isPrimary
          recruitingClass
          jerseyNumber
          weight
          height
          positions
        }
      }
    `)
      .then((data:any) => (data.player as Player))
      .catch(console.error);
  }

  function fetchPlayerPAIPercentile (
    playerId:number,
    teamId:number,
    position:string,
  ):Promise<number> {
    return gql(`
      playerPAIStats (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}"
      ) {
        paiStats {
          div1Stats {
            percentile
          }
        }
      }
    `)
      .then((data:any) => get(data, 'playerPAIStats.paiStats') as PlayerPAIStats)
      .then((paiStats:PlayerPAIStats) => Math.round(get(paiStats, 'div1Stats.percentile', 0)))
      .catch(error => {
        console.error(error);
        return 0;
      })
      .finally(() => setLoading(false));
  }

  function fetchPlayerCombinePercentile (
    playerId:number,
    teamId:number,
    position:string,
  ):Promise<number> {
    return gql(`
      playerCombineStats (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}"
      ) {
        combineStats {
          div1Stats {
            percentile
          }
        }
      }
    `)
      .then((data:any) => get(data, 'playerCombineStats.combineStats') as PlayerCombineStats)
      .then((combineStats:PlayerCombineStats) => Math.round(get(combineStats, 'div1Stats.percentile', 0)))
      .catch(error => {
        console.error(error);
        return 0;
      })
      .finally(() => setLoading(false));
  }

  function fetchPlayerPPIPercentile (
    playerId:number,
    teamId:number,
    position:string,
  ):Promise<number> {
    return gql(`
    playerPPIStats (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}"
      ) {
        div1Stats {
          percentile
        }
      }
    `)
      .then((data:any) => get(data, 'playerPPIStats') as PlayerPPIStats)
      .then((combineStats:PlayerPPIStats) => Math.round(get(combineStats, 'div1Stats.percentile', 0)))
      .catch(error => {
        console.error(error);
        return 0;
      })
      .finally(() => setLoading(false));
  }

  function onUpdatePlayers (updatedPlayers:DepthChartPlayer[]) {
    onUpdatePositions(
      (positions || []).map(position => ({
        ...position,
        players: updatedPlayers.length === 1
          ? position.players.map(player => ({
            ...(player.id === updatedPlayers[0].id ? updatedPlayers[0] : player),
          })) as DepthChartPlayer[]
          : (position.players[0].id === updatedPlayers[0].id
              ? [...updatedPlayers]
              : position.players
          ) as DepthChartPlayer[],
      })) as DepthChartPosition[]
    );
  }

  function onUpdateLabel (updatedPosition:DepthChartPosition, updatedLabel:string) {
    onUpdatePositions(
      (positions || []).map(position => ({
        ...position,
        label: position.id === updatedPosition.id
          ? updatedLabel
          : position.label,
      })) as DepthChartPosition[],
    );
  }

  function createEmptyDepthChartPlayer ():DepthChartPlayer {
    return ({
      // generate a fake unique ID, remember to replace with the actual DepthChartPlayer id
      id: Math.round(Math.random() * 1000000),
      jerseyNumber: null,
      playerId: null,
      playerName: null,
      player: null,
      height: null,
      weight: null,
      class: null,
      note: null,
    }) as DepthChartPlayer;
  }

  function createDepthChartPlayerFromPlayer (depthChartPlayer:DepthChartPlayer) {
    return async (player:Player | void):Promise<DepthChartPlayer> => {
      const primaryCollege:College = ((player || {} as Player).playerColleges || [])
        .find((college:College) => college && college.isPrimary) || ({} as College);
      const primarySchool:HighSchool = ((player || {} as Player).highSchools || [])
        .find((school:HighSchool) => school && school.isPrimary) || ({} as HighSchool);
      const height = primaryCollege.height || primarySchool.height || 0;

      const schoolPrimaryPosition = (((primarySchool || {}).positions || [])
        .find((position:any) => position && position.isPrimary) || {}).code;
      const collegePrimaryPosition = (((primaryCollege || {}).positions || [])
        .find((position:any) => position && position.isPrimary) || {}).code;
      const primaryPosition = collegePrimaryPosition || schoolPrimaryPosition || '';

      const [paiPercentile, combinePercentile, ppiPercentile] = player && player.id && team && team.id
        ? await Promise.all([
            fetchPlayerPAIPercentile(player.id, team.id, primaryPosition),
            fetchPlayerCombinePercentile(player.id, team.id, primaryPosition),
            fetchPlayerPPIPercentile(player.id, team.id, primaryPosition)
          ])
        : [];

      return ({
        id: depthChartPlayer.id,
        jerseyNumber: primaryCollege.jerseyNumber || null,
        playerId: player ? player.id : null,
        player: player || null,
        playerName: '',
        height: height ? inchesToFeetAndInches(height) : '',
        weight: primaryCollege.weight || primarySchool.weight || 0,
        class: primaryCollege.recruitingClass,
        note: '',
        paiPercentile: paiPercentile,
        combinePercentile: combinePercentile,
        ppiPercentile: ppiPercentile,
      }) as DepthChartPlayer;
    };
  }

  function generateDepthChartPlayers (players:Player[]):Promise<DepthChartPlayer[]> {
    setTeamDepthChartLoading(true);

    return Promise.all(players.map((player:Player) => {
      const newDepthChartPlayer:DepthChartPlayer = createEmptyDepthChartPlayer();

      return fetchPlayerDetails(player.id)
        .then(createDepthChartPlayerFromPlayer(newDepthChartPlayer))
        .catch(() => newDepthChartPlayer);
    }))
      .finally(() => setTeamDepthChartLoading(false));
  }

  function onAddPlayers () {
    if (!selectedAddPlayers || !selectedAddPlayers.length) return;

    generateDepthChartPlayers(selectedAddPlayers)
      .then((newDepthChartPlayers:DepthChartPlayer[]) => {
        const newDepthChartPositions = [...(positions || []).sort(sortPositions)]
          .map((position:DepthChartPosition) => {
            // if current position has 0 or 1 defined players (with playerId) - fill with selected players
            if (newDepthChartPlayers.length > 0 && (position.players[0] && !position.players[0].playerId)) {
              const depthChartPlayerId = position.players[0].id;
              position.players[0] = {
                ...newDepthChartPlayers.shift(),
                id: depthChartPlayerId,
              } as DepthChartPlayer;
            }

            if (newDepthChartPlayers.length > 0 && (position.players[1] && !position.players[1].playerId)) {
              const depthChartPlayerId = position.players[1].id;
              position.players[1] = {
                ...newDepthChartPlayers.shift(),
                id: depthChartPlayerId,
              } as DepthChartPlayer;
            }

            return position;
          });

        onUpdatePositions(newDepthChartPositions);
        setSelectedAddPlayers([]);
      })
  }

  function isPlayerSelected (player:DepthChartPlayer) {
    return !!(selectedDepthChartPlayers
      .find((selectedPlayer:DepthChartPlayer) => selectedPlayer.id === player.id));
  }

  function onRemovePlayers () {
    if (selectedDepthChartPlayers.length > 0) {
      onUpdatePositions(
        (positions || []).map((position) => ({
          ...position,
          players: position.players.map((player:DepthChartPlayer) => {
            return isPlayerSelected(player)
              ? {
                ...createEmptyDepthChartPlayer(),
                id: player.id,
              }
              : player;
          }) as DepthChartPlayer[],
        })) as DepthChartPosition[]
      );
    }

    setSelectedDepthChartPlayers([]);
  }

  function sortPositions (first:DepthChartPosition, second:DepthChartPosition) {
    if (first.order < second.order) return -1;
    if (first.order > second.order) return 1;

    return 0;
  }

  function onXLSDownload() {
    if (depthChartId) {
      setLoading(true);

      exportXLSReport(
        XLSExportType.DEPTH_CHART,
        { id: depthChartId, type: CHART_TYPE[type] },
      )
        .catch(error => {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  function generateAndDownloadPDFReport () {
    if (!depthChartSlug) return;

    setLoading(true);

    exportPDFReport(
      PDFExportPage.DEPTH_CHART,
      depthChartSlug,
      { 'depth-chart-type': CHART_TYPE[type] },
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function downloadIndividualPlayerPDF (slug:string) {
    return () => {
      setLoading(true);

      exportPDFReport(
        PDFExportPage.PLAYER,
        slug,
      )
        .catch(error => {
          console.error(error);
          showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    };
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  return (
    <div className={clsx(classes.teamDepthChart, className)}>
      <Loader inProgress={teamDepthChartLoading} />

      {team && (
        <div className={classes.header}>
          <div 
          className={!printed ? classes.titleWrapper : ''} 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
          >
            <div className={clsx(classes.title, printed && classes.printTitle)}>
              {type === DepthChartType.DEFENSIVE && 'Defense'}
              {type === DepthChartType.OFFENSIVE && 'Offense'}
              {type === DepthChartType.SPECIAL && 'Special Teams'}
            </div>

            {
            statsBeingCalculated && (
              <div style={{color: 'red'}}>
                Calculating Depth Chart statistics, please wait.
              </div>
            )
            }

            {(!printed && !!teamPlayers.length) && (
              <AddDepthChartPlayers
                className={clsx(
                  classes.addPlayersButton,
                  printed && classes.hidden
                )}
                players={teamPlayers}
                selectedPlayers={selectedAddPlayers}
                onSelect={setSelectedAddPlayers}
                onAdd={onAddPlayers}
              />
            )}
          </div>
          {
            !printed ? 
            <>
              <div
                className={clsx(
                  classes.actions,
                  printed && classes.hidden
                )}
              >
                <Action
                  className={clsx(classes.action, !selectedDepthChartPlayers.length && classes.disabledAction)}
                  icon={RemoveIcon}
                  iconClassName={classes.actionIcon}
                  disabled={!selectedDepthChartPlayers.length}
                  onClick={onRemovePlayers}
                >
                  <span className={classes.actionText}>Remove</span>
                </Action>

                <div className={classes.downloadButtons}>
                  {(user.accessLevel.exportAllowanceType !== 'none') && (
                    <Download label='XLS' onClick={onXLSDownload} 
                    disabled={statsBeingCalculated}
                    />
                  )}

                  <Download
                    label='PDF'
                    disabled={statsBeingCalculated}
                    onClick={generateAndDownloadPDFReport}
                  />
                </div>
              </div>
            </>
            : null
          }
        </div>
      )}

      <DepthChart
        depthChartPositions={positionsToRender}
        selectedPlayers={selectedDepthChartPlayers}
        setSelectedPlayers={setSelectedDepthChartPlayers}
        editedPlayers={editedDepthChartPlayers}
        setEditedPlayers={setEditedDepthChartPlayers}
        teamPlayers={teamPlayers}
        fetchPlayerDetails={fetchPlayerDetails}
        createDepthChartPlayerFromPlayer={createDepthChartPlayerFromPlayer}
        printed={printed}
        onUpdatePlayers={onUpdatePlayers}
        onUpdatePositions={onUpdatePositions}
        onUpdateLabel={onUpdateLabel}
        downloadIndividualPlayerPDF={downloadIndividualPlayerPDF}
        statsBeingCalculated={statsBeingCalculated}
      />

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}
